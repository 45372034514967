import React from "react"
import styled from "styled-components"

import Header from "../components/Header"
import SideNav from "../components/SideNav"

const Root = styled.div``

const Content = styled.div`
  padding: 1rem;
  max-width: 1080px;
`

const Wrapper = styled.div`
  display: flex;
`

interface Props {
  showSidebar?: boolean
}

const DefaultLayout: React.FC<Props> = ({ children, showSidebar = true }) => {
  return (
    <Root>
      <Header></Header>
      <Wrapper>
        { showSidebar ? <SideNav /> : null }
        <Content>{children}</Content>
      </Wrapper>
    </Root>
  )
}

export default DefaultLayout
