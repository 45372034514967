import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"
import styled from 'styled-components'

const Root = styled.div`
  padding: 1rem;
  border-right: 1px solid #efefef;
  max-width: 200px;
`

interface NavLinkProps {
  indent?: boolean
  isCurrentPage?: boolean
}

const NavLink = styled(Link)<NavLinkProps>`
  display: block;
  margin-bottom: 0.5rem;
  text-decoration: none;
  margin-left: ${props => props.indent ? "1rem" : "0"};
  font-weight: ${props => props.isCurrentPage ? "bold": "initial"};
`

const SideNav = () => {
  const location = useLocation()
  const result = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { slug: { ne: null } } }
        sort: {order: ASC, fields: frontmatter___slug}
        ) {
        edges {
          node {
            frontmatter {
              slug
              title
              shortTitle
            }
            html
          }
        }
      }
    }
  `)

  return (
    <Root>
      {result.allMarkdownRemark.edges.map(edge => {
        const node = edge.node
        const indent = node.frontmatter.slug.substr(1).includes("/") ? true : false
        return (
          <NavLink
            key={node.frontmatter.slug}
            indent={indent}
            isCurrentPage={location.pathname === node.frontmatter.slug}
            to={node.frontmatter.slug}>{node.frontmatter.shortTitle ? node.frontmatter.shortTitle : node.frontmatter.title}</NavLink>
        )
      })}
    </Root>
  )
}

export default SideNav


