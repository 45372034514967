import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Helmet from "react-helmet"

import DefaultLayout from "../components/DefaultLayout"

const Title = styled.h1`
  margin-top: 0;
`

const Root = styled.div``

export default function BlogPost({ data }) {
  const post = data.markdownRemark
  return (
    <DefaultLayout>
      <Helmet title={post.frontmatter.title}></Helmet>
      <Root>
        <Title>{post.frontmatter.title}</Title>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </Root>
    </DefaultLayout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
