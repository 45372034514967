import React from "react"
import { Link } from "gatsby"
import logo from "../images/logo.png"
import styled from "styled-components"

const LogoImg = styled.img`
  width: 100px;
`
const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`

const BackLink = styled.div`
`

const Header = () => {
  return (
    <HeaderDiv>
      <Link to={`/`}><LogoImg src={logo} /></Link>
      <BackLink>
        <a href="https://app.morta.io">Back to dashboard</a>
      </BackLink>
    </HeaderDiv>
  )
}

export default Header
